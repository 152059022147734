const shortfilmTime = 40;
const Base_Url = 'https://api.linalien.nomoredomains.sbs';

const largeScreen = 1280;
const middleScreen = 990;
const smallScreen = 630;

const countOfMoviesOnDesktop = 4;
const countOfMoviesOnProTab = 3;
const countOfMoviesOnTab = 2;
const countOfMoviesOnPhone = 1;

export { shortfilmTime, Base_Url, largeScreen, middleScreen, smallScreen, countOfMoviesOnDesktop, countOfMoviesOnProTab, countOfMoviesOnTab, countOfMoviesOnPhone };